import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { getApp } from 'firebase/app';
import { getFirestore, collection, doc, getDoc, setDoc, query, where, getDocs, onSnapshot, orderBy, limit, startAfter } from 'firebase/firestore';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { FIREBASE_API } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  links: [],
  linkFail: false,
  link: ""
};

const slice = createSlice({
  name: 'links',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LINKS
    getLinksSuccess(state, action) {
      state.isLoading = false;
      state.links = action.payload;
    },

    // GET LINK (SINGULAR)
    getLinkSuccess(state, action) {
      state.isLoading = false;
      state.link = action.payload.link;
    },

    // GET LINK FAILED
    getLinkFail(state, action) {
      state.linkFail = true;
    }
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLinks() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const db = getFirestore(); // Get a reference to the Firestore database
      const linksCollection = collection(db, 'links'); // Reference to the 'links' collection in Firestore

      const querySnapshot = await getDocs(linksCollection); // Fetch data from Firestore collection

      const links = [];
      querySnapshot.forEach((doc) => {
        // Iterate through the documents and extract data
        links.push({ id: doc.id, ...doc.data() });
      });

      dispatch(slice.actions.getLinksSuccess(links)); // Dispatch the success action with the retrieved data
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLink(linkId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(`Attempting to get ${linkId}`);
      const db = getFirestore(); // Get a reference to the Firestore database
      const linkDocument = doc(db, 'links', linkId); // Reference to the link document in Firestore

      const docSnapshot = await getDoc(linkDocument); // Fetch data snapshot from Firestore document

      dispatch(slice.actions.getLinkSuccess(docSnapshot.data())); // Dispatch the success action with the retrieved data
    } catch (error) {
      dispatch(slice.actions.getLinkFail());
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------