import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import { getApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword
} from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, setDoc, query, where, getDocs, onSnapshot, orderBy, limit, startAfter } from 'firebase/firestore';
//
import { FIREBASE_API } from '../config';

// ----------------------------------------------------------------------

const firebaseApp = getApp();

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

const initialState = {
  isInitialized: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIALIZE') {
    const { meta, issues } = action.payload;
    return {
      ...state,
      isInitialized: true,
      meta,
      issues,
    };
  }
  return state;
};

const DataContext = createContext({
  ...initialState
});

// ----------------------------------------------------------------------

DataProvider.propTypes = {
  children: PropTypes.node,
};

async function saveContact( name, business, email, message ) {
  const userRef = doc(collection(DB, 'messages'), `${Math.round(Date.now() / 1000)}`);

  await setDoc(userRef, {
    name,
    business,
    email,
    message
  });
};

async function addLink( shortCode, link, permission, expiresAt ) {
  const linkRef = doc(collection(DB, 'links'), shortCode);

  await setDoc(linkRef, {
    link,
    permission,
    expiresAt
  });
};

function DataProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    async () =>
      {
        if(!state.isInitialized) {
          // INITIALIZE
          dispatch({
            type: 'INITIALIZE',
            payload: { isInitialized: true },
          });
        }
      },
    [dispatch]
  );

  const sendContact = (name, business, email, message) => saveContact(name, business, email, message);

  return (
    <DataContext.Provider
      value={{
        ...state,
        sendContact,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export { DataContext, DataProvider };
