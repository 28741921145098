import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  isOpenContactModal: false,
};

const slice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenContactModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenContactModal = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal } = slice.actions;

// ----------------------------------------------------------------------